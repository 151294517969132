import React from 'react'

/* eslint max-len: ['error', { 'code': 5000 }] */
const SvgComponent = props => (
  <svg width='26px' height='12px' viewBox='0 0 26 12' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g id='Hönnun' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Forsíða-V2' transform='translate(-190.000000, -1056.000000)' fill='#00A69C' fillRule='nonzero'>
        <g id='Group-10' transform='translate(149.000000, 1008.000000)'>
          <g id='swimmer' transform='translate(41.000000, 48.000000)'>
            <path d='M25.675,10.003125 L24.7,10.003125 C23.642125,10.003125 22.839375,9.6610625 22.4213437,9.2759375 C22.0597813,8.94240625 21.6226563,8.703125 21.1306875,8.703125 L20.4685,8.703125 C19.9765312,8.703125 19.5394062,8.94240625 19.1778438,9.2759375 C18.760625,9.6610625 17.957875,10.003125 16.9,10.003125 C15.842125,10.003125 15.039375,9.6610625 14.6213437,9.2759375 C14.2597812,8.94240625 13.8226562,8.703125 13.3306875,8.703125 L12.6685,8.703125 C12.1765312,8.703125 11.7394063,8.94240625 11.3778437,9.2759375 C10.960625,9.6610625 10.157875,10.003125 9.1,10.003125 C8.042125,10.003125 7.239375,9.6610625 6.82134375,9.2759375 C6.45978125,8.94240625 6.02265625,8.703125 5.5306875,8.703125 L4.8685,8.703125 C4.37653125,8.703125 3.93940625,8.94240625 3.57784375,9.2759375 C3.160625,9.6610625 2.357875,10.003125 1.3,10.003125 L0.325,10.003125 C0.1454375,10.003125 0,10.1485625 0,10.328125 L0,10.978125 C0,11.1576875 0.1454375,11.303125 0.325,11.303125 L1.3,11.303125 C2.8689375,11.303125 4.25425,10.8079063 5.2,10.009625 C6.14575,10.8083125 7.5310625,11.303125 9.1,11.303125 C10.6689375,11.303125 12.05425,10.8079063 13,10.009625 C13.94575,10.8083125 15.3310625,11.303125 16.9,11.303125 C18.4689375,11.303125 19.85425,10.8079063 20.8,10.009625 C21.74575,10.8083125 23.1310625,11.303125 24.7,11.303125 L25.675,11.303125 C25.8545625,11.303125 26,11.1576875 26,10.978125 L26,10.328125 C26,10.1485625 25.8545625,10.003125 25.675,10.003125 Z' id='Shape' />
            <path d='M23.4964844,5.3671875 L22.6890625,5.3671875 C21.8130098,5.3671875 21.1482324,5.08391699 20.8020503,4.76498535 C20.5026313,4.48877979 20.1406372,4.290625 19.7332256,4.290625 L19.1848516,4.290625 C18.7774399,4.290625 18.4154458,4.48877979 18.1160269,4.76498535 C17.7705176,5.08391699 17.1057402,5.3671875 16.2296875,5.3671875 C15.3536348,5.3671875 14.6888574,5.08391699 14.3426753,4.76498535 C14.0432563,4.48877979 13.6812622,4.290625 13.2738506,4.290625 L12.7254766,4.290625 C12.3180649,4.290625 11.9560708,4.48877979 11.6566519,4.76498535 C11.3111426,5.08391699 10.6463652,5.3671875 9.7703125,5.3671875 C8.89425977,5.3671875 8.22948242,5.08391699 7.88330029,4.76498535 C7.58388135,4.48877979 7.22188721,4.290625 6.81447559,4.290625 L6.26610156,4.290625 C5.85868994,4.290625 5.4966958,4.48877979 5.19727686,4.76498535 C4.85176758,5.08391699 4.18699023,5.3671875 3.3109375,5.3671875 L2.50351562,5.3671875 C2.35481543,5.3671875 2.234375,5.48762793 2.234375,5.63632813 L2.234375,6.17460938 C2.234375,6.32330957 2.35481543,6.44375 2.50351562,6.44375 L3.3109375,6.44375 C4.61021387,6.44375 5.75742578,6.03364697 6.540625,5.37257031 C7.32382422,6.0339834 8.47103613,6.44375 9.7703125,6.44375 C11.0695889,6.44375 12.2168008,6.03364697 13,5.37257031 C13.7831992,6.0339834 14.9304111,6.44375 16.2296875,6.44375 C17.5289639,6.44375 18.6761758,6.03364697 19.459375,5.37257031 C20.2425742,6.0339834 21.3897861,6.44375 22.6890625,6.44375 L23.4964844,6.44375 C23.6451846,6.44375 23.765625,6.32330957 23.765625,6.17460938 L23.765625,5.63632813 C23.765625,5.48762793 23.6451846,5.3671875 23.4964844,5.3671875 Z' id='Shape-Copy-7' />
            <path d='M21.3179688,0.853125 L20.678125,0.853125 C19.9838945,0.853125 19.4570898,0.628646484 19.1827568,0.375908203 C18.9454814,0.15702832 18.6586182,0 18.3357637,0 L17.9012031,0 C17.5783486,0 17.2914854,0.15702832 17.05421,0.375908203 C16.7804102,0.628646484 16.2536055,0.853125 15.559375,0.853125 C14.8651445,0.853125 14.3383398,0.628646484 14.0640068,0.375908203 C13.8267314,0.15702832 13.5398682,0 13.2170137,0 L12.7824531,0 C12.4595986,0 12.1727354,0.15702832 11.93546,0.375908203 C11.6616602,0.628646484 11.1348555,0.853125 10.440625,0.853125 C9.74639453,0.853125 9.21958984,0.628646484 8.94525684,0.375908203 C8.70798145,0.15702832 8.42111816,0 8.09826367,0 L7.66370312,0 C7.34084863,0 7.05398535,0.15702832 6.81670996,0.375908203 C6.54291016,0.628646484 6.01610547,0.853125 5.321875,0.853125 L4.68203125,0.853125 C4.56419336,0.853125 4.46875,0.948568359 4.46875,1.06640625 L4.46875,1.49296875 C4.46875,1.61080664 4.56419336,1.70625 4.68203125,1.70625 L5.321875,1.70625 C6.35149023,1.70625 7.26060156,1.3812627 7.88125,0.857390625 C8.50189844,1.3815293 9.41100977,1.70625 10.440625,1.70625 C11.4702402,1.70625 12.3793516,1.3812627 13,0.857390625 C13.6206484,1.3815293 14.5297598,1.70625 15.559375,1.70625 C16.5889902,1.70625 17.4981016,1.3812627 18.11875,0.857390625 C18.7393984,1.3815293 19.6485098,1.70625 20.678125,1.70625 L21.3179688,1.70625 C21.4358066,1.70625 21.53125,1.61080664 21.53125,1.49296875 L21.53125,1.06640625 C21.53125,0.948568359 21.4358066,0.853125 21.3179688,0.853125 Z' id='Shape-Copy-8' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent
