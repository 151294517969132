import React from 'react'
import ReactPlayer from 'react-player'
import * as styles from './ModalVideo.module.scss'

const ModalVideo = ({ url }) => {
  return (
    <div className={styles.video}>
      <ReactPlayer
        width={728}
        height={410}
        style={{
          margin: '0 auto',
          maxWidth: '100%',
          height: 'auto'
        }}
        playing
        controls
        url={url}
      />
    </div>
  )
}

export default ModalVideo
