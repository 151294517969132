import React from 'react'

/* eslint max-len: ["error", { "code": 5000 }] */
const SvgComponent = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width='22px' height='28px' viewBox='0 0 22 28'>
    <g id='Hönnun' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Forsíða-V2' transform='translate(-1233.000000, -3162.000000)' fill='#FFFFFF' fillRule='nonzero'>
        <g id='Footer' transform='translate(0.000000, 3089.000000)'>
          <g id='travel-bus' transform='translate(1233.000000, 73.000000)'>
            <path d='M15.125,24.75 L6.875,24.75 L6.875,26.125 C6.875,26.8843915 6.25939153,27.5 5.5,27.5 L4.125,27.5 C3.36560847,27.5 2.75,26.8843915 2.75,26.125 L2.75,24.75 C1.23121694,24.75 0,23.5187831 0,22 L0,2.75 C0,1.2375 1.2375,0 2.75,0 L19.25,0 C20.7687831,9.29986408e-17 22,1.23121694 22,2.75 L22,22 C22,23.5187831 20.7687831,24.75 19.25,24.75 L19.25,26.125 C19.25,26.8843915 18.6343915,27.5 17.875,27.5 L16.5,27.5 C15.7406085,27.5 15.125,26.8843915 15.125,26.125 L15.125,24.75 Z M2.75,6.875 L2.75,15.125 L9.625,15.125 L9.625,6.875 L2.75,6.875 Z M12.375,6.875 L12.375,15.125 L19.25,15.125 L19.25,6.875 L12.375,6.875 Z M4.125,2.75 L4.125,4.125 L17.875,4.125 L17.875,2.75 L4.125,2.75 Z M4.8125,22 C5.9515873,22 6.875,21.0765873 6.875,19.9375 C6.875,18.7984127 5.9515873,17.875 4.8125,17.875 C3.6734127,17.875 2.75,18.7984127 2.75,19.9375 C2.75,21.0765873 3.6734127,22 4.8125,22 Z M17.1875,22 C18.3265873,22 19.25,21.0765873 19.25,19.9375 C19.25,18.7984127 18.3265873,17.875 17.1875,17.875 C16.0484127,17.875 15.125,18.7984127 15.125,19.9375 C15.125,21.0765873 16.0484127,22 17.1875,22 Z' id='Shape' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent
