import React from 'react'
import * as styles from './Footer.module.scss'
import classNames from 'classnames'
import footerLogo from '../../images/logo-footer.svg'
import FacebookIcon from '../../images/svg-components/FacebookIcon'
import CarIcon from '../../images/svg-components/CarIcon'
import LocationIcon from '../../images/svg-components/LocationCurrent'
import PhoneIcon from '../../images/svg-components/Phone'
import TravelBusIcon from '../../images/svg-components/TravelBus'
import Envelope from '../../images/svg-components/Envelope'
import { Link } from '../'

const iconFieldMapper = {
  facebook: FacebookIcon,
  car: () => <CarIcon fill='#ffffff' />,
  location: LocationIcon,
  telephone: PhoneIcon,
  transit: TravelBusIcon,
  email: Envelope
}

const LinkItem = ({ layout, link: { icon, url, title } }) => {
  const Icon = iconFieldMapper[icon]

  if (!Icon) {
    throw new Error(`Unrecognized icon: '${icon}'`)
  }

  const style = layout === 'vertical'
    ? styles.linkVertical
    : ''

  return (
    <div className={classNames(styles.link, style)}>
      <Link to={url}>
        <Icon />
        <div>{title}</div>
      </Link>
    </div>
  )
}

const Footer = ({ isHomepage, linksLeft, linksRight, linksLeftTitle, skilmalarTexti, skilmalarUrl, personuverndTexti, personuverndUrl }) => {
  const containerClass = classNames(
    styles.container,
    'footer-container',
    'container-fluid',
    'mt-6'
  )

  const outerContainer = classNames({
    [styles.outerContainer]: isHomepage
  })

  return (
    <div className={outerContainer}>
      <div className={containerClass}>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12 col-md-3'>
              <div className={styles.logoAndTerms}>
                <Link to='/'>
                  <img src={footerLogo} alt='Herjólfur footer' />
                </Link>
                <Link to={skilmalarUrl} className="mt-4">
                  {skilmalarTexti}
                </Link>
                <Link to={personuverndUrl} className="mt-1">
                  {personuverndTexti}
                </Link>
              </div>
            </div>
            <div className='col-sm-12 col-md-4 mt-4 mt-md-0'>
              <h3>{linksLeftTitle}</h3>
              {linksLeft.map((link, i) => (
                <LinkItem
                  layout='horizontal'
                  key={i}
                  link={link}
                />
              ))}
            </div>
            <div className='col-sm-12 col-md-5 mt-4 mt-md-0'>
              <div className='d-flex justify-content-md-end'>
                {linksRight.map((link, i) => (
                  <LinkItem
                    layout='vertical'
                    key={i}
                    link={link}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
