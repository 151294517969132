import React, { Component } from 'react'
import Cmp from './EyjarMask'
import { StaticQuery, graphql } from 'gatsby'

class Connected extends Component {
  render () {
    return (
      <StaticQuery
        query={graphql`
          query {
            allContentfulHomepage(filter: {node_locale: {eq: "en-US"}}) {
              edges {
                node {
                  heroVideo {
                    file {
                      url
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => <Cmp data={data} {...this.props} />}
      />
    )
  }
}

export default Connected
