import React from 'react'

/* eslint max-len: ["error", { "code": 500 }] */
const SvgComponent = props => (
  <svg width='17px' height='14px' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17 14'>
    <g id='Hönnun' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Forsíða-V2' transform='translate(-442.000000, -3259.000000)' fill='#FFFFFF' fillRule='nonzero'>
        <g id='Footer' transform='translate(0.000000, 3089.000000)'>
          <g id='envelope' transform='translate(442.000000, 170.697860)'>
            <path d='M14.625,0 C15.5224627,0 16.25,0.727537282 16.25,1.625 L16.25,11.375 C16.25,12.2724627 15.5224627,13 14.625,13 L1.625,13 C0.727537282,13 1.09907485e-16,12.2724627 0,11.375 L0,1.625 C0,0.73125 0.73125,0 1.625,0 L14.625,0 Z M11.074375,7.39375 L16.25,11.375 L16.25,9.75 L12.09,6.58125 L16.25,3.25 L16.25,1.625 L8.125,8.125 L0,1.625 L0,3.25 L4.16,6.58125 L0,9.75 L0,11.375 L5.175625,7.39375 L8.125,9.75 L11.074375,7.39375 Z' id='Shape' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent
