import React, { Component } from "react";
import classNames from "classnames";
import * as styles from "./HomepageBookingForm.module.scss";
import { Form } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import CalendarDark from "../../images/svg-components/CalendarDark";
import Ship from "../../images/svg-components/Ship";
import DatePicker, { registerLocale } from "react-datepicker";
import { Button, TripSelect } from "../../components";
import { BOOKING } from "../../misc/bookingFormData";
import { is } from "date-fns/locale";
import format from "date-fns/format";
import { TRANSLATIONS } from "../../misc/translations";

registerLocale("is", is); // register it with the name you want

class HomepageBookingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromPort: "LAN",
      toPort: "VEY",
      fromDate: new Date(),
      toDate: new Date(),
    };

    this.handleChangePort = this.handleChangePort.bind(this);
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.handleTooltipClick = this.handleTooltipClick.bind(this);
    this.bookNow = this.bookNow.bind(this);
  }

  handleChangePort({ fromPort, toPort }) {
    this.setState({ fromPort, toPort });
  }

  handleChangeStart(day) {
    const toDate = new Date(this.state.toDate);
    this.setState({
      fromDate: day,
      toDate: day.getTime() > toDate.getTime() ? day : toDate,
    });
  }

  handleChangeEnd(day) {
    this.setState({ toDate: day });
  }

  handleTooltipClick(e) {
    e.preventDefault();
  }

  bookNow(e) {
    e.preventDefault();
    const { urlBase } = BOOKING;
    const { fromDate, toDate, fromPort } = this.state;
    const { oneWay, currentLang } = this.props;
    const formatStr = "yyyy-MM-dd";
    const d1 = fromDate
      ? "?d1=" + format(fromDate, formatStr, { locale: is })
      : "";
    const d2 =
      fromDate && !oneWay && toDate
        ? "&d2=" + format(toDate, formatStr, { locale: is })
        : "";
    const port = fromPort ? "&port=" + fromPort : "";

    const href = urlBase[currentLang] + d1 + d2 + port + "&reset=true";

    window.location.href = href;
    // // open in new tab in dev, otherwise redirect
    // process.env.NODE_ENV === "development"
    //   ? window.open(href, "_blank")
    //   : (window.location.href = href);
  }

  render() {
    const { oneWay, currentLang } = this.props;
    const { fromDate, toDate, toPort, fromPort } = this.state;
    const shipIcon = classNames(styles.icon, styles.shipIcon);
    const calanderIcon = classNames(styles.icon, styles.calanderIcon);

    const datePickerSettings = {
      formatWeekDay: (nameOfDay) => nameOfDay.substr(0, 1).toUpperCase(),
      locale: "is",
    };

    return (
      <Form>
        <div className="row">
          <div className={classNames("col-sm-6", { "col-sm-12": oneWay })}>
            <Form.Group controlId="Booking.trip1">
              <Form.Label>{oneWay ? "Ferð" : "Ferð 1"}</Form.Label>
              <TripSelect port={fromPort} onChange={this.handleChangePort} />
              <div className={shipIcon}>
                <Ship />
              </div>
            </Form.Group>
          </div>

          {!oneWay && (
            <div className="col-sm-6">
              <Form.Group controlId="Booking.trip2">
                <Form.Label>Ferð 2</Form.Label>
                <TripSelect
                  port={toPort}
                  returnTrip
                  onChange={this.handleChangePort}
                />
                <div className={shipIcon}>
                  <Ship />
                </div>
              </Form.Group>
            </div>
          )}

          <div className={classNames("col-sm-6", { "col-sm-12": oneWay })}>
            <Form.Group controlId="Booking.fromDate">
              <Form.Label>{TRANSLATIONS[currentLang].DATEFROM}</Form.Label>
              <DatePicker
                className={styles.datePicker}
                {...datePickerSettings}
                minDate={new Date()}
                selected={fromDate}
                onChange={this.handleChangeStart}
                value={
                  fromDate && currentLang === "is-IS"
                    ? format(fromDate, "dd/MM/yyyy")
                    : format(fromDate, "MM/dd/yyyy")
                }

              />
              <div className={calanderIcon}>
                <CalendarDark />
              </div>
            </Form.Group>
          </div>

          {!oneWay && (
            <div className="col-sm-6">
              <Form.Group controlId="Booking.toDate">
                <Form.Label>{TRANSLATIONS[currentLang].DATETO}</Form.Label>
                <DatePicker
                  className={styles.datePicker}
                  {...datePickerSettings}
                  minDate={new Date(fromDate)}
                  selected={toDate}
                  onChange={this.handleChangeEnd}
                  value={
                    toDate && currentLang === "is-IS"
                      ? format(toDate, "dd/MM/yyyy")
                      : format(toDate, "MM/dd/yyyy")
                  }
                />
                <div className={calanderIcon}>
                  <CalendarDark />
                </div>
              </Form.Group>
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-lg-5 align-self-center">
            <Button onClick={this.bookNow}>
              {TRANSLATIONS[currentLang].BOOKTRIP}
            </Button>
          </div>
        </div>
      </Form>
    );
  }
}

export default HomepageBookingForm;
