import React from 'react'
import Link from '../Link'

const StaffMember = ({ jobTitle, title, category }) => {
  return (
    <tr>
      <td>
        {title}
      </td>
      <td>
        {category}
      </td>
      <td>
        {jobTitle}
      </td>
    </tr>
  )
}

export default StaffMember
