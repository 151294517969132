import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { get } from 'lodash'

const ContainerHero = ({ content, pageTitle }) => {
  const imageFluid = get(content, 'image.gatsbyImageData', null)

  return (
    <div>
      {imageFluid &&
        <div className='row'>
          <div className='col-12'>
            <GatsbyImage image={imageFluid} alt='' />
          </div>
        </div>
      }
    </div>
  )
}

export default ContainerHero
