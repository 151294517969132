import React, { useState } from 'react'
import * as styles from './TimelineCard.module.scss'
import ReactModal from 'react-modal'
import PlayBlack from '../../images/svg-components/PlayBlack.js'
import Expand from '../../images/svg-components/Expand.js'
import { ModalVideo } from '../'
import { ReactBnbGallery } from 'react-bnb-gallery'
import classNames from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'

const TimelineCard = ({ video, title, text, date, imageGallery, media, cardImage }) => {
  const hasMedia = video || imageGallery
  const [isOpen, setIsOpen] = useState(false)
  const gallery = imageGallery ? imageGallery.map(item => {
    return item.file.url
  }) : null
  return (
    <div className={styles.container}>
      <div className={styles.mediaContent}>
        {hasMedia &&
          <button
            onClick={() => setIsOpen(true)}
            className={styles.icon}
          >
            {video &&
              <PlayBlack />
            }
            {gallery &&
              <Expand />
            }
          </button>
        }
        <button
          className={classNames(
            styles.openMediaButton,
            styles.hasMeidaConent
          )}
          onClick={() => setIsOpen(true)}
        >
          {cardImage &&
            <GatsbyImage image={cardImage.fixedImage} alt='' />
          }
        </button>
      </div>
      <div className={classNames(
        styles.infoSection,
        { [styles.borderTop]: !cardImage }
      )}>
        <div className='clr-green pb-3'>{date}</div>
        <h3>{title}</h3>
        <p>{text.text}</p>
      </div>
      {gallery &&
        <ReactBnbGallery
          show={isOpen}
          photos={gallery}
          onClose={() => setIsOpen(false)} />
      }
      <ReactModal
        isOpen={isOpen}
        shouldCloseOnOverlayClick
        onRequestClose={() => setIsOpen(false)}
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        {video &&
          <ModalVideo url={video.file.url} />
        }
        {cardImage && !video &&
          <GatsbyImage image={cardImage.largeImage} alt='' />
        }
      </ReactModal>
    </div>
  )
}

export default TimelineCard
