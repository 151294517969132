import React from 'react'
import * as styles from './HeroImage.module.scss'
import { GatsbyImage } from 'gatsby-plugin-image'

const HeroImage = ({ content }) => (
  <div className='row justify-content-center'>
    <div className='col-10'>
      <GatsbyImage image={content.image.gatsbyImageData} className={styles.image} alt='' />
    </div>
  </div>
)

export default HeroImage
