import React from 'react'

const SvgComponent = props => (
  <svg viewBox='0 0 8 12' {...props}>
    <title>{'Chevron-right'}</title>
    <g id='Hönnun' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Forsíða-V2' transform='translate(-749.000000, -1431.000000)' fill='#2D2D2D'>
        <g id='Group-7' transform='translate(149.000000, 1370.000000)'>
          <g id='cheveron-right' transform='translate(600.000000, 61.000000)'>
            <polygon id='Path' points='6.95 6.707 7.657 6 2 0.343 0.586 1.757 4.828 6 0.586 10.243 2 11.657' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent
