import React from 'react'

/* eslint max-len: ['error', { 'code': 1000 }] */
const SvgComponent = () => (
  <svg width='16px' height='19px' viewBox='0 0 16 19' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <title>C1B67A25-D8DC-4F3A-8244-637F3FDB6702</title>
    <desc>Created with sketchtool.</desc>
    <g id='Hönnun' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Saga-Herjólfs' transform='translate(-1195.000000, -1049.000000)' fill='#000000' fillRule='nonzero'>
        <g id='Bitmap-Copy' transform='translate(775.000000, 1028.000000)'>
          <g id='Group' transform='translate(412.000000, 15.000000)'>
            <g id='play' transform='translate(8.000000, 6.000000)'>
              <path d='M15.1571429,7.66785714 L2.58571429,0.235714286 C1.56428571,-0.367857143 7.15558332e-13,0.217857143 7.15558332e-13,1.71071429 L7.15558332e-13,16.5714286 C7.15558332e-13,17.9107143 1.45357143,18.7178571 2.58571429,18.0464286 L15.1571429,10.6178571 C16.2785714,9.95714286 16.2821429,8.32857143 15.1571429,7.66785714 Z' id='Path' />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent
