import React, { useState } from 'react'
import * as styles from './Staff.module.scss'
import { StaffMember, FAQ } from '../'
import classNames from 'classnames'
import { TRANSLATIONS } from '../../misc/translations'

const Staff = (data) => {
  const { allStaffMembers, title, questionRows } = data.content
  const currentLang = data.nodeLocale

  return (
    <section>
      <div className='container'>
        <div className='row'>
          <div className='col offset-md-1 pb-2'>
            <h1>{title}</h1>
          </div>
        </div>
        <div className='row'>
          <div className={classNames(styles.staffList, 'col-md-9 offset-md-1')}>
            <table className='w-100'>
              {allStaffMembers &&
                <>
                  <thead>
                    <tr>
                      <td>{TRANSLATIONS[currentLang].NAME}</td>
                      <td>{TRANSLATIONS[currentLang].DEPARTMENT}</td>
                      <td>{TRANSLATIONS[currentLang].JOBTITLE}</td>
                    </tr>
                  </thead>
                  <tbody>
                    {allStaffMembers.map(({
                      title,
                      jobTitle,
                      category
                    }, key) => {
                      const categoryName = category
                        ? category.categoryName
                        : ''
                      return (
                        <StaffMember
                          key={key}
                          title={title}
                          jobTitle={jobTitle}
                          category={categoryName}
                        />
                      )
                    }
                  )}
                  </tbody>
                </>
              }
              {questionRows &&
                <tbody>
                  {questionRows.map(({ question, answers }, i) =>
                    <FAQ
                      key={i}
                      question={question}
                      answers={answers}
                    />
                  )}
                </tbody>
              }
            </table>
          </div>
        </div>
      </div>
    </section >
  )
}

export default Staff
