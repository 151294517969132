import React from 'react'
import { get } from 'lodash'

const ContainerTextContentCentered = ({ content }) => {
  const cont = get(content, 'body.childMarkdownRemark.html')
  return (
    <div className='row justify-content-center'>
      <div className='col-10'>
        <div className='wysiwyg-content t-align--center' dangerouslySetInnerHTML={{ __html: cont }} />
      </div>
    </div>
  )
}

export default ContainerTextContentCentered
