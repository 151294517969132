export const TRANSLATIONS = {
  'is-IS': {
    BOTHWAYS: 'Báðar leiðir',
    ONEWAY: 'Aðra leið',
    SEARCHTRIPS: 'Leita að ferð',
    NEXTTRIPS: 'Næstu ferðir',
    SEEALLTRIPS: 'Sjá allar ferðir',
    SEEALLRECOMMENDED: 'Sjá allt merkilegt',
    DATEFROM: 'Brottför',
    DATETO: 'Heimkoma',
    VEHICLETYPE: 'Tegund farartækis',
    NOOFPERSONS: 'Fjöldi farþega',
    ADDRESSINEYJUM: 'Lögheimili í Eyjum',
    CALL: 'Hringja',
    ORSENDMESSAGE: 'Eða sendu okkur skilaboð og við svörum um hæl',
    SENDMESSAGE: 'Senda skilaboð',
    FULLNAME: 'Fullt nafn',
    EMAIL: 'Netfang',
    PHONENUMBER: 'Símanúmer',
    HOWCANWEASSIST: 'Hvað getum við aðstoðað þig með?',
    SEEMORE: 'Sjá nánar',
    SEEALLNOTIFICATIONS: 'Sjá allar tilkynningar',
    NONOTIFICATIONS: 'Engar tilkynningar eins og er',
    CONTACTTHANKYOU: 'Takk fyrir að hafa samband',
    CONTACTERROR: 'Því miður hefur komið upp villa, þú getur haft samband við okkur á herjolfur@herjolfur.is',
    BOOKTRIP: 'Bóka ferð',
    WAVEHEIGHT: 'Ölduhæð',
    WINDSPEED: 'Vindhraði',
    TEMPERATURE: 'Lofthiti',
    SEETHEROADCONSTRUCTIONFORECAST: 'Skoða spá vegagerðarinnar',
    TODAY: 'Í dag',
    NAME: 'Nafn',
    DEPARTMENT: 'Svið',
    JOBTITLE: 'Starfsheiti'
  },
  'en-US': {
    BOTHWAYS: 'Both ways',
    ONEWAY: 'One way',
    SEARCHTRIPS: 'Search trips',
    NEXTTRIPS: 'Next trips',
    SEEALLTRIPS: 'See all trips',
    SEEALLRECOMMENDED: 'See all recommendations',
    DATEFROM: 'Departure',
    DATETO: 'Return',
    VEHICLETYPE: 'Vehicle type',
    NOOFPERSONS: 'Number of passengers',
    ADDRESSINEYJUM: 'Address in Vestmannaeyjar',
    CALL: 'Call',
    ORSENDMESSAGE: 'Or send us a message and we\'ll respond quickly',
    SENDMESSAGE: 'Send message',
    FULLNAME: 'Full name',
    EMAIL: 'Email',
    PHONENUMBER: 'Phone number',
    HOWCANWEASSIST: 'How can we assist you?',
    SEEMORE: 'See more',
    SEEALLNOTIFICATIONS: 'See all announcements',
    NONOTIFICATIONS: 'No announcements at the moment',
    CONTACTTHANKYOU: 'Thanks for contacting us',
    CONTACTERROR: 'We were unable to process your request please contact us at herjolfur@herjolfur.is',
    BOOKTRIP: 'Book trip',
    WAVEHEIGHT: 'Wave height',
    WINDSPEED: 'Wind speed',
    TEMPERATURE: 'Temperature',
    SEETHEROADCONSTRUCTIONFORECAST: 'See the sea condition forcast',
    TODAY: 'Today',
    NAME: 'Name',
    DEPARTMENT: 'Department',
    JOBTITLE: 'Job title'
  }
}
