import React from 'react'

/* eslint max-len: ['error', { 'code': 5000 }] */
const SvgComponent = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width='14px' height='22px' viewBox='0 0 14 22' {...props}>
    <g id='Hönnun' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='banner' transform='translate(-95.000000, -22.000000)' fill='#FFFFFF' fillRule='nonzero'>
        <g id='Group-2'>
          <g id='cheveron-left' transform='translate(95.000000, 22.000000)'>
            <polygon id='Path' points='1.96875 9.924375 0.643125 11.25 11.25 21.856875 13.90125 19.205625 5.9475 11.25 13.90125 3.294375 11.25 0.643125' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent
