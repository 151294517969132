import React from 'react'

/* eslint max-len: ['error', { 'code': 5000 }] */
const SvgComponent = props => (
  <svg width='39px' height='39px' viewBox='0 0 39 39' xmlns='http://www.w3.org/2000/svg'>
    <g id='Hönnun' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Forsíða-V2' transform='translate(-139.000000, -731.000000)' fillRule='nonzero'>
        <g id='Group-5' transform='translate(139.000000, 731.000000)'>
          <g id='Group-8'>
            <circle id='Oval' fill='#D96C06' cx='19.5' cy='19.5' r='19.5' />
            <g id='exclamation' transform='translate(16.000000, 8.000000)' fill='#FFFFFF'>
              <path d='M7,18.9 C7,20.8299 5.4299,22.4 3.5,22.4 C1.5701,22.4 0,20.8299 0,18.9 C0,16.9701 1.5701,15.4 3.5,15.4 C5.4299,15.4 7,16.9701 7,18.9 Z M0.405125,1.10245625 L1.000125,13.0024563 C1.02808125,13.561275 1.48929375,14 2.0488125,14 L4.9511875,14 C5.51070625,14 5.97191875,13.561275 5.999875,13.0024563 L6.594875,1.10245625 C6.62484375,0.5026875 6.1467,0 5.5461875,0 L1.4538125,0 C0.8533,0 0.37515625,0.5026875 0.405125,1.10245625 Z' id='Shape' />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent
