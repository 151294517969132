import React from 'react'

const PageTitle = ({ content }) => {
  const { pageTitle } = content
  return (
    <div className='row justify-content-center'>
      <div className='col-10'>
        <h1 className='hdln--1 mt-4 t-align--center'>{pageTitle}</h1>
      </div>
    </div>
  )
}

export default PageTitle
