import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

const TabsCmp = ({ content: { grouped } }) => {
  return (
    <div className='row justify-content-center' >
      <div className='col-12'>
        <Tabs className='react-tabs react-tabs--no-border'>
          <TabList className='ml-1'>
            {
              grouped.map((obj, i) =>
                <Tab key={i}>
                  <h3>{obj.title}</h3>
                </Tab>
              )
            }
          </TabList>
          {
            grouped.map((obj, i) =>
              <TabPanel key={i}>
                <div className='pt-4' dangerouslySetInnerHTML={{ __html: obj.body.childMarkdownRemark.html }} />
              </TabPanel>
            )
          }
        </Tabs>
      </div>
    </div>
  )
}

export default TabsCmp
