import React from 'react'

/* eslint max-len: ['error', { 'code': 5000 }] */
const SvgComponent = props => (
  <svg width='21px' height='21px' viewBox='0 0 21 21' version='1.1' xmlns='http://www.w3.org/2000/svg' xlink='http://www.w3.org/1999/xlink'>
    <g id='Hönnun' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Forsíða-V2' transform='translate(-560.000000, -1051.000000)' fill='#00A69C' fillRule='nonzero'>
        <g id='Group-10' transform='translate(149.000000, 1008.000000)'>
          <g id='0072-sun' transform='translate(411.000000, 43.000000)'>
            <path d='M10.5,4.42105263 C10.1949474,4.42105263 9.94736842,4.17347368 9.94736842,3.86842105 L9.94736842,0.552631579 C9.94736842,0.247578947 10.1949474,0 10.5,0 C10.8050526,0 11.0526316,0.247578947 11.0526316,0.552631579 L11.0526316,3.86842105 C11.0526316,4.17347368 10.8050526,4.42105263 10.5,4.42105263 Z' id='Path' />
            <path d='M3.86842105,11.0526316 L0.552631579,11.0526316 C0.247578947,11.0526316 0,10.8050526 0,10.5 C0,10.1949474 0.247578947,9.94736842 0.552631579,9.94736842 L3.86842105,9.94736842 C4.17347368,9.94736842 4.42105263,10.1949474 4.42105263,10.5 C4.42105263,10.8050526 4.17347368,11.0526316 3.86842105,11.0526316 Z' id='Path' />
            <path d='M20.4473684,11.0526316 L17.1315789,11.0526316 C16.8265263,11.0526316 16.5789474,10.8050526 16.5789474,10.5 C16.5789474,10.1949474 16.8265263,9.94736842 17.1315789,9.94736842 L20.4473684,9.94736842 C20.7524211,9.94736842 21,10.1949474 21,10.5 C21,10.8050526 20.7524211,11.0526316 20.4473684,11.0526316 Z' id='Path' />
            <path d='M10.5,21 C10.1949474,21 9.94736842,20.7524211 9.94736842,20.4473684 L9.94736842,17.1315789 C9.94736842,16.8265263 10.1949474,16.5789474 10.5,16.5789474 C10.8050526,16.5789474 11.0526316,16.8265263 11.0526316,17.1315789 L11.0526316,20.4473684 C11.0526316,20.7524211 10.8050526,21 10.5,21 Z' id='Path' />
            <path d='M10.5,6.63931579 C11.5334211,6.63931579 12.5049474,7.04052632 13.2355263,7.77110526 C13.9661053,8.50168421 14.3684211,9.46878947 14.3684211,10.5 C14.3684211,11.5312105 13.9661053,12.4994211 13.2355263,13.2288947 C12.5049474,13.9583684 11.5334211,14.3606842 10.5,14.3606842 C9.46657895,14.3606842 8.49505263,13.9594737 7.76447368,13.2288947 C7.03389474,12.4994211 6.63157895,11.5312105 6.63157895,10.5 C6.63157895,9.46878947 7.03389474,8.50057895 7.76447368,7.77110526 C8.49505263,7.04163158 9.46657895,6.63931579 10.5,6.63931579 Z M5.52631579,10.5 C5.52631579,13.2432632 7.75342105,15.4659474 10.5,15.4659474 C13.2465789,15.4659474 15.4736842,13.2432632 15.4736842,10.5 C15.4736842,7.75784211 13.2465789,5.53405263 10.5,5.53405263 C7.75342105,5.53405263 5.52631579,7.75673684 5.52631579,10.5 Z' id='Shape' />
            <path d='M6.08668421,6.08668421 C5.94521053,6.08668421 5.80373684,6.03252632 5.69542105,5.92531579 L3.35115789,3.58105263 C3.13563158,3.36552632 3.13563158,3.01515789 3.35115789,2.79963158 C3.56668421,2.58410526 3.91705263,2.58410526 4.13257895,2.79963158 L6.47684211,5.14389474 C6.69236842,5.35942105 6.69236842,5.70978947 6.47684211,5.92531579 C6.36852632,6.03363158 6.22705263,6.08668421 6.08557895,6.08668421 L6.08668421,6.08668421 Z' id='Path' />
            <path d='M3.74242105,18.3628421 C3.60094737,18.3628421 3.45947368,18.3086842 3.35115789,18.2014737 C3.13563158,17.9859474 3.13563158,17.6355789 3.35115789,17.4200526 L5.69542105,15.0757895 C5.91094737,14.8602632 6.26131579,14.8602632 6.47684211,15.0757895 C6.69236842,15.2913158 6.69236842,15.6416842 6.47684211,15.8572105 L4.13257895,18.2014737 C4.02426316,18.3097895 3.88278947,18.3628421 3.74131579,18.3628421 L3.74242105,18.3628421 Z' id='Path' />
            <path d='M14.9133158,6.08668421 C14.7718421,6.08668421 14.6303684,6.03252632 14.5220526,5.92531579 C14.3065263,5.70978947 14.3065263,5.35942105 14.5220526,5.14389474 L16.8663158,2.79963158 C17.0818421,2.58410526 17.4322105,2.58410526 17.6477368,2.79963158 C17.8632632,3.01515789 17.8632632,3.36552632 17.6477368,3.58105263 L15.3034737,5.92531579 C15.1951579,6.03363158 15.0536842,6.08668421 14.9122105,6.08668421 L14.9133158,6.08668421 Z' id='Path' />
            <path d='M17.2575789,18.3628421 C17.1161053,18.3628421 16.9746316,18.3086842 16.8663158,18.2014737 L14.5220526,15.8572105 C14.3065263,15.6416842 14.3065263,15.2913158 14.5220526,15.0757895 C14.7375789,14.8602632 15.0879474,14.8602632 15.3034737,15.0757895 L17.6477368,17.4200526 C17.8632632,17.6355789 17.8632632,17.9859474 17.6477368,18.2014737 C17.5394211,18.3097895 17.3979474,18.3628421 17.2564737,18.3628421 L17.2575789,18.3628421 Z' id='Path' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent
