import React from 'react'

/* eslint max-len: ["error", { "code": 1000 }] */
const SvgComponent = props => (
  <svg width='13px' height='17px' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 17'>
    <g id='Hönnun' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Forsíða-V2' transform='translate(-442.000000, -3199.000000)' fill='#FFFFFF'>
        <g id='Footer' transform='translate(0.000000, 3089.000000)'>
          <g id='phone' transform='translate(448.133148, 118.605116) rotate(12.000000) translate(-448.133148, -118.605116) translate(440.633148, 111.105116)'>
            <path d='M14.7129714,13.0853764 L14.7129714,13.5403764 C14.7129714,13.9269757 14.3995707,14.2403764 14.0129714,14.2403764 L12.6129714,14.2403764 C6.04078288,14.2403764 0.712971401,8.91256491 0.712971401,2.34037639 L0.712971401,0.940376392 C0.712971401,0.553777067 1.02637208,0.240376392 1.4129714,0.240376392 L4.2129714,0.240376392 C4.59957073,0.240376392 4.9129714,0.553777067 4.9129714,0.940376392 L4.9129714,3.74037639 C4.9129714,4.13237639 4.6959714,4.65737639 4.4229714,4.93037639 L2.9249714,6.42837639 C3.9889714,8.94837639 6.0049714,10.9643764 8.5249714,12.0283764 L10.0089714,10.5443764 C10.2889714,10.2643764 10.8139714,10.0473764 11.1989714,10.0473764 L14.0129714,10.0473764 C14.3968343,10.0512152 14.7059906,10.3634943 14.7059714,10.7473764 L14.7059714,13.0923764 L14.7129714,13.0853764 Z' id='Path' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent
