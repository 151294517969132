import React from 'react'
import * as styles from './Card.module.scss'
import classnames from 'classnames'
import { Link } from '../'
import { GatsbyImage } from 'gatsby-plugin-image'
import { has } from 'lodash'

const Card = ({ image, text, link, i, trackClick = false }) => {
  const containerClass = classnames('swiper-slide', styles.container, {
    [styles.isEven]: (i + 1) % 2 === 0,
  })
  const linkClass = classnames('hdln--3', [styles.link])
  const hasImage = has(image, 'gatsbyImageData')
  return (
    hasImage && (
      <div className={containerClass}>
        <div>
          <GatsbyImage image={image.gatsbyImageData} alt={text} />
        </div>
        <Link to={link} className={linkClass}>
          {text}
        </Link>
      </div>
    )
  )
}

export default Card
