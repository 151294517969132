export const BOOKING = {
  urlBase: {
    'is-IS': process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://book.herjolfur.is',
    'en-US': process.env.NODE_ENV === 'development' ? 'http://localhost:3000/en' : 'https://book.herjolfur.is/passengers'
  },
  locations: {
    variation1: {
      'en-US': 'Landeyjahöfn',
      'is-IS': 'Landeyjahöfn'
    },
    vestmannaeyjar: {
      'en-US': 'Vestmannaeyjar',
      'is-IS': 'Vestmannaeyjar'
    }
  },
  vehicleTypes: {
    NONE: {
      'en-US': {
        nicename: 'Choose vehicle'
      },
      'is-IS': {
        nicename: 'Veldu farartæki'
      }
    },
    BG: {
      'en-US': {
        nicename: 'Vehicle 0-5m',
        description: 'Standard size car'
      },
      'is-IS': {
        nicename: 'Farartæki 0-5m',
        description: 'Fólksbíll - óbreyttur jeppi'
      }
    },
    BGH: {
      'en-US': {
        nicename: 'Vehicle 0-5m and higher than 2,15m',
        description: 'e.g. crossover car w/ cargo box'
      },
      'is-IS': {
        nicename: 'Farartæki 0-5m og hærri en 2,15m',
        description: 'T.d. jepplingur m/ tengdamömmubox'
      }
    },
    XL: {
      'en-US': {
        nicename: 'Vehicle 5-7m',
        description: 'Longer car'
      },
      'is-IS': {
        nicename: 'Farartæki 5-7m',
        description: 'Lengri bíll'
      }
    },
    XLH: {
      'en-US': {
        nicename: 'Vehicle 5-7m and higher than 2,15m',
        description: 'e.g. motorhome'
      },
      'is-IS': {
        nicename: 'Farartæki 5-7m og hærri en 2,15m',
        description: 'T.d. húsbíll'
      }
    },
    BF: {
      'en-US': {
        nicename: 'Motorcycle'
      },
      'is-IS': {
        nicename: 'Mótórhjól'
      }
    },
    BGCR: {
      'en-US': {
        nicename: 'Vehicle w/trailer 6-10m',
        description: 'e.g. car + camper'
      },
      'is-IS': {
        nicename: 'Farartæki m/vagn 6-10m',
        description: 'T.d. bíll + fellihýsi'
      }
    },
    BGCRH: {
      'en-US': {
        nicename: 'Vehicle w/trailer 6-10m and higher than 2,15m',
        description: 'e.g. car + horse trailer'
      },
      'is-IS': {
        nicename: 'Farartæki m/vagn 6-10m og hærra en 2,15m',
        description: 'T.d. bíll + hestakerra'
      }
    },
    XLCR: {
      'en-US': {
        nicename: 'Vehicle w/trailer 10-16m'
      },
      'is-IS': {
        nicename: 'Farartæki m/vagn 10-16m'
      }
    },
    XLCRH: {
      'en-US': {
        nicename: 'Vehicle w/trailer 10-16m and higher than 2,15m',
        description: 'e.g. car + caravan'
      },
      'is-IS': {
        nicename: 'Farartæki m/vagn 10-16m og hærra en 2,15m',
        description: 'T.d. bíll + hjólhýsi'
      }
    },
    RH: {
      'en-US': {
        nicename: 'Bicycle'
      },
      'is-IS': {
        nicename: 'Reiðhjól'
      }
    }
  },
  passengerValues: {
    min: 0,
    max: 10
  },
  passengerTypes: {
    adults: {
      nicename: {
        'en-US': 'Adults',
        'is-IS': 'Fullorðnir'
      },
      fieldName: 'FU'
    },
    children: {
      nicename: {
        'en-US': 'Children (11 years and younger)',
        'is-IS': 'Börn (11 ára og yngri)'
      },
      fieldName: 'FM'
    }
  }
}
