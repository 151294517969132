import React from 'react'
import classNames from 'classnames'
import * as styles from './WrapperTwoCol.module.scss'
import { get } from 'lodash'
import { GatsbyImage } from 'gatsby-plugin-image'

const WrapperTwoCol = ({ content }) => {
  const containerClass = classNames('row', 'justify-content-md-center', styles.container)
  const contentArr = get(content, 'content', null)

  return (
    <div className={containerClass}>
      {contentArr && contentArr.map((cont, i) => {
        const switchName = cont.__typename
        switch (switchName) {
          case 'ContentfulCmpMainContent':
            const body = get(cont, 'body.childMarkdownRemark.html', '')
            return (
              <div className='col-md-6' key={i}>
                <div className='wysiwyg-content' dangerouslySetInnerHTML={{ __html: body }} />
              </div>
            )
          case 'ContentfulCmpSideImage':
            const image = get(cont, 'image.gatsbyImageData', null)
            return (
              <div className='col-md-4' key={i}>
                {image &&
                  <GatsbyImage image={image} alt='' />
                }
              </div>
            )
        }
      })}
    </div>
  )
}

export default WrapperTwoCol
