import React from 'react'

/* eslint max-len: ["error", { "code": 5000 }] */
const SvgComponent = props => (
  <svg width='14px' height='14px' viewBox='0 0 14 14'>
    <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='globe' fill='#2D2D2D' fillRule='nonzero'>
        <g id='globe-path'>
          <path d='M7,14 C3.13400675,14 4.73447626e-16,10.8659932 0,7 C-4.73447626e-16,3.13400675 3.13400675,7.10171439e-16 7,0 C10.8659932,2.36723813e-16 14,3.13400675 14,7 C14,10.8659932 10.8659932,14 7,14 Z M8.4,12.425 C9.48457791,12.1440197 10.45994,11.544172 11.2,10.703 L11.2,6.3 C10.4268014,6.3 9.8,5.67319865 9.8,4.9 L9.8,2.149 C9.15393739,1.77739644 8.43981222,1.53935472 7.7,1.449 L7.7,2.1 C7.7,2.87319865 7.07319865,3.5 6.3,3.5 L6.3,4.2 C6.3,4.97319865 5.67319865,5.6 4.9,5.6 L4.9,7 L7,7 C7.77319865,7 8.4,7.62680135 8.4,8.4 L8.4,12.425 Z M5.6,12.425 L5.6,10.5 C4.82680135,10.5 4.2,9.87319865 4.2,9.1 L4.2,8.4 L3.85,8.4 C3.27010101,8.4 2.8,7.92989899 2.8,7.35 L2.8,5.6 L1.575,5.6 C0.804323143,8.59578853 2.60538729,11.6497669 5.6,12.425 Z' id='Shape' />
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent
