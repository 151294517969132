import React from 'react'

/* eslint max-len: ['error', { 'code': 5000 }] */
const SvgComponent = props => (
  <svg width='26px' height='19px' viewBox='0 0 26 19' version='1.1' xmlns='http://www.w3.org/2000/svg' xlink='http://www.w3.org/1999/xlink'>
    <g id='Hönnun' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Forsíða-V2' transform='translate(-369.000000, -1053.000000)' fill='#00A69C' fillRule='nonzero'>
        <g id='Group-10' transform='translate(149.000000, 1008.000000)'>
          <g id='cloud' transform='translate(220.000000, 45.000000)'>
            <path d='M23.2253125,8.40125 C23.3390625,7.9990625 23.4,7.580625 23.4,7.15 C23.4,4.6353125 21.3646875,2.6 18.85,2.6 C18.1715625,2.6 17.5134375,2.74625 16.9,3.03875 C15.61625,1.210625 13.4753125,0 11.05,0 C7.215,0 4.0746875,3.0265625 3.908125,6.833125 C1.5925,7.645625 0,9.8434375 0,12.35 C0,15.58375 2.61625,18.2 5.85,18.2 L20.8,18.2 C23.6721875,18.2 26,15.87625 26,13 C26,11.090625 24.951875,9.31125 23.2253125,8.40125 Z M20.8,16.9 L5.85,16.9 C3.3353125,16.9 1.3,14.8646875 1.3,12.35 C1.3,10.0425 3.014375,8.1371875 5.240625,7.840625 C5.2121875,7.613125 5.2,7.3815625 5.2,7.15 C5.2,3.9203125 7.8203125,1.3 11.05,1.3 C13.4996875,1.3 15.5959375,2.803125 16.469375,4.94 C17.0625,4.3021875 17.9075,3.9 18.85,3.9 C20.645625,3.9 22.1,5.354375 22.1,7.15 C22.1,7.9015625 21.8440625,8.59625 21.4134375,9.14875 C23.278125,9.44125 24.7,11.0540625 24.7,13 C24.7,15.153125 22.953125,16.9 20.8,16.9 Z' id='Shape' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent
