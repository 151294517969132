import React from 'react'
import classNames from 'classnames'
import Swiper from 'swiper'
import * as styles from './Slider.module.scss'
import ReactResizeDetector from 'react-resize-detector'
import Arrow from '../../images/svg-components/Arrow.js'

class Slider extends React.Component {
  constructor () {
    super()

    this.onResize = this.onResize.bind(this)
  }

  componentDidMount () {
    this.swiper = new Swiper(
      this.scrollContainer,
      { ...this.props }
    )
  }

  componentWillUnmount () {
    this.swiper.destroy()
  }

  onResize () {
    this.swiper.update()
  }

  render () {
    const {
      children,
      noExtraSlide,
      hasIndicators,
      totalSlides
    } = this.props

    return (
      <div className={classNames('slider-container', {
        'ml-0 mr-0 slider-container--hide-buttons': totalSlides < 2
      })}>
        <div
          ref={el => { this.scrollContainer = el }}
          className='swiper-container'>

          <div className={classNames('swiper-wrapper', 'container', styles.wrapper)}>
            {children}
            {!noExtraSlide &&
              <div className='swiper-slide' />
            }
          </div>
        </div>

        {hasIndicators &&
          <div>
            <div className='swiper-button swiper-button--prev'>
              <Arrow />
            </div>
            <div className='swiper-button swiper-button--next'>
              <Arrow />
            </div>
          </div>
        }

        {/* bootstrap styles race condition fix */}
        <ReactResizeDetector handleWidth onResize={this.onResize} />
      </div>
    )
  }
}

/* https://idangero.us/swiper/api/#parameters */
Slider.defaultProps = {
  slidesPerView: 4.5,
  slidesPerGroup: 3,
  spaceBetween: 30,
  grabCursor: true,
  freeMode: true,
  freeModeMomentumBounce: false,
  freeModeMomentumRatio: 0.5,
  preventClicksPropagation: false,
  preventClicks: true,
  onTap: (sw, e) => {
    e.preventDefault()
    sw.allowClick = false
  },
  mousewheel: {
    forceToAxis: true
  },
  breakpoints: {
    768: {
      slidesPerView: 1.5
    },
    900: {
      slidesPerView: 2.5
    },
    1300: {
      slidesPerView: 3.5
    }
  }
}

export default Slider
