import React, { Component } from 'react'
import Cmp from './Notifications.js'
import { StaticQuery, graphql } from 'gatsby'

class Connected extends Component {
  render () {
    return (
      <StaticQuery
        query={graphql`
          query {
            isNotifications: allContentfulNotifications(
              filter: {
                active: {eq: true},
                node_locale: {eq: "is-IS"}
              },
              sort: {
                fields: createdAt,
                order: DESC
              }
            ) {
              edges {
                node {
                  id
                  node_locale
                  contentful_id
                  title
                  active
                  orangeDisplay
                  content {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
              }
            }
            enNotifications: allContentfulNotifications(
              filter: {
                active: {eq: true},
                node_locale: {eq: "en-US"}
              },
              sort: {
                fields: createdAt,
                order: DESC
              }
            ) {
              edges {
                node {
                  id
                  node_locale
                  contentful_id
                  title
                  active
                  orangeDisplay
                  content {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => <Cmp data={data} {...this.props} />}
      />
    )
  }
}

export default Connected
