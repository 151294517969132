import React from 'react'
import { get } from 'lodash'
import { Card } from '../'

const AllRecommended = ({ data, content }) => {
  const recommendedItems = get(data, 'allContentfulRecommended.edges', [])
  return (
    <div className='row'>
      {recommendedItems.map((item, i) => {
        return (
          <div className='col-6 col-md-4 mb-4' key={i}>
            <Card
              text={item.node.title}
              image={item.node.image}
              link={item.node.url}
            />
          </div>
        )
      })}
    </div>
  )
}

export default AllRecommended
