import React from 'react'

/* eslint max-len: ['error', { 'code': 5000 }] */
const SvgComponent = props => (
  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.172 7.49823L10.101 1.4165L11.515 0L20 8.5L19.293 9.20825L11.515 17L10.101 15.5835L16.172 9.50177H0V7.49823H16.172Z" fill="#2D2D2D"/>
  </svg>
)

export default SvgComponent
