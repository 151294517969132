import React from 'react'
import Footer from './Footer.js'
import { useStaticQuery, graphql } from 'gatsby'

const footerQuery = graphql`
  fragment FooterContent on ContentfulFooterConnection {
    edges {
      node {
        title
        node_locale
        titleLinks
        links {
          title
          icon
          url
        }
        linksHelpful {
          title
          icon
          url
        }
        skilmalarTexti
        skilmalar {
          file {
            url
          }
        }
        personuverndTexti
        personuvernd {
          file {
            url
          }
        }
      }
    }
  }

  query {
    isFooter: allContentfulFooter(filter: {node_locale: {eq: "is-IS"}}) {
      ...FooterContent
    }
    enFooter: allContentfulFooter(filter: {node_locale: {eq: "en-US"}}) {
      ...FooterContent
    }
  }
`

export default props => {
  const data = useStaticQuery(footerQuery)
  const { currentLang } = props
  const currentLocaleData = currentLang === 'is-IS'
    ? data.isFooter
    : data.enFooter

  const {
    edges: [
      {
        node: {
          links: linksLeft,
          linksHelpful: linksRight,
          titleLinks: linksLeftTitle,
          skilmalarTexti,
          skilmalar: {
            file: { url: skilmalarUrl }
          },
          personuverndTexti,
          personuvernd: {
            file: { url: personuverndUrl }
          }
        }
      }
    ]
  } = currentLocaleData

  return (
    <Footer
      linksLeftTitle={linksLeftTitle}
      linksLeft={linksLeft}
      linksRight={linksRight}
      skilmalarTexti={skilmalarTexti}
      skilmalarUrl={skilmalarUrl}
      personuverndTexti={personuverndTexti}
      personuverndUrl={personuverndUrl}
      {...props}
    />
  )
}
