import React, { useState } from 'react'
import fetch from 'isomorphic-fetch'
import { Button, Link } from '../'
import Envelope from '../../images/svg-components/Envelope'
import Phone from '../../images/svg-components/Phone'
import { TRANSLATIONS } from '../../misc/translations'

const ContactForm = ({ nodeLocale }) => {
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber ] = useState('')
  const [description, setDescription] = useState('')
  const [sent, setSent] = useState(false)
  const [error, setError] = useState(false)
  const {MAIL_ENVIRONMENT} = process.env

  const IsValid = () => {
    return fullName && email && phoneNumber && description
  }

  const sendEmail = (e) => {
    e.preventDefault()
    if (IsValid()) {
      fetch('https://mail.herjolfur.is', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          fullName,
          email,
          phoneNumber,
          description
        })
      })
        .then((response) => {
          if (response.ok) {
            setSent(true)
          } else {
            setError(true)
          }
        })
        .catch(() => {
          setError(true)
        })
    }
  }

  return (
    <div>
      <div className='t-align--center'>
        <Link to='tel:+3544812800' >
          <Button icon={<Phone />}>
            {TRANSLATIONS[nodeLocale].CALL}
          </Button>
        </Link>
      </div>
      <hr />
      <div className='row justify-content-md-center'>
        <h2 className='mb-2 mr-4 ml-4 t-align--center'>{TRANSLATIONS[nodeLocale].ORSENDMESSAGE}</h2>
        <div className='col-md-10'>
          { !sent && !error && <form>
            <div className='row'>
              <div className='form-group col-md-4 p-3'>
                <label htmlFor='fulltnafn'>{TRANSLATIONS[nodeLocale].FULLNAME}*</label>
                <input
                  type='text'
                  className='form-control'
                  id='fulltnafn'
                  value={fullName}
                  onChange={e => setFullName(e.target.value)}
                />
              </div>
              <div className='form-group col-md-4 p-3'>
                <label htmlFor='netfang'>{TRANSLATIONS[nodeLocale].EMAIL}*</label>
                <input
                  type='email'
                  className='form-control'
                  id='netfang'
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
              </div>
              <div className='form-group col-md-4 p-3'>
                <label htmlFor='simanumer'>{TRANSLATIONS[nodeLocale].PHONENUMBER}*</label>
                <input
                  type='text'
                  className='form-control'
                  id='simanumer'
                  value={phoneNumber}
                  onChange={e => setPhoneNumber(e.target.value)}
                />
              </div>
              <div className='form-group col-sm-12 p-3'>
                <label htmlFor='skilabod'>{TRANSLATIONS[nodeLocale].HOWCANWEASSIST}*</label>
                <textarea
                  className='form-control'
                  id='skilabod'
                  value={description}
                  onChange={e => setDescription(e.target.value)}
                />
              </div>
              <div className='col-sm-12 t-align--center'>
                <Button
                  icon={<Envelope />}
                  onClick={sendEmail}
                >
                  {TRANSLATIONS[nodeLocale].SENDMESSAGE}
                </Button>
              </div>
            </div>
          </form>}
          {sent && <div class='wysiwyg-content t-align--center'><p>{TRANSLATIONS[nodeLocale].CONTACTTHANKYOU}</p></div>}
          {!sent && error && <div class='wysiwyg-content t-align--center'><p>{TRANSLATIONS[nodeLocale].CONTACTERROR}</p></div>}
        </div>
      </div>
    </div>
  )
}

export default ContactForm
