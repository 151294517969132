// extracted by mini-css-extract-plugin
export var borderTop = "TimelineCard-module--borderTop--om20a";
export var container = "TimelineCard-module--container--DotQ9";
export var hasMeidaConent = "TimelineCard-module--hasMeidaConent--lq-QG";
export var icon = "TimelineCard-module--icon--zsYrF";
export var infoSection = "TimelineCard-module--infoSection--Uj3oT";
export var mediaContent = "TimelineCard-module--mediaContent--7Lq24";
export var modal = "TimelineCard-module--modal--IHDu-";
export var modalImage = "TimelineCard-module--modalImage--LCnAJ";
export var openMediaButton = "TimelineCard-module--openMediaButton--jlJQo";
export var overlay = "TimelineCard-module--overlay--kmgIj";