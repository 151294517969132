import React, { useState } from 'react'
import * as styles from './FAQ.module.scss'
import classNames from 'classnames'
import Minus from '../../images/svg-components/Minus'
import Plus from '../../images/svg-components/Plus'
import { GaEvent } from '../'

const FAQ = ({ question, answers }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <tr className={styles.tableRow} >
      <td>
        <GaEvent
          question={question}
          isOpen={isOpen}
          onClick={() => setIsOpen(!isOpen)}
        >
          <button
            className={classNames(
              styles.row,
              'w-100 d-flex justify-content-between align-items-center'
            )}
          >
            <h3>{question}</h3>
            {isOpen ? <Minus /> : <Plus />}
          </button>
        </GaEvent>
        <div
          className={classNames(
            'my-2',
            { 'd-none': !isOpen }
          )}
        >
          {answers &&
            <div
              className={styles.answer}
              dangerouslySetInnerHTML={{ __html: answers.childMarkdownRemark.html }}
            />
          }
        </div>
      </td>
    </tr>
  )
}

export default FAQ
