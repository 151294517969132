import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import classnames from 'classnames'
import { Header, Footer, EyjarMask } from '../../components'

import * as styles from './Main.module.scss'
import '../../styles/index.scss'

export default ({ children, className, displayEyjarMask, currentLang, currentPageId, isHomepage }) => {
  const containerClass = classnames(styles.container, className)
  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={
        data => (
          <Fragment>
            <Helmet>
              <meta name='viewport' content='width=device-width, initial-scale=1' />
              <title>{data.site.siteMetadata.title}</title>
              <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png' />
              <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png' />
              <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png' />
              <link rel='android-chrome-icon' type='image/png' sizes='192x192' href='/android-chrome-192x192.png' />
              <link rel='android-chrome-icon' type='image/png' sizes='512x512' href='/android-chrome-512x512.png' /> 
            </Helmet>
            <Header displayEyjarMask={displayEyjarMask} currentLang={currentLang} currentPageId={currentPageId} />
            {displayEyjarMask &&
              <EyjarMask />
            }
            <div className={containerClass}>
              {children}
            </div>
            <Footer currentLang={currentLang} isHomepage={isHomepage} />
          </Fragment>
        )
      }
    />
  )
}
