
import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { HomepageBookingForm } from '../'
import { TRANSLATIONS } from '../../misc/translations.js'

class SectionBookingForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      oneWay: false
    }
  }

  render () {
    const { currentLang } = this.props
    const { oneWay } = this.state

    return (
      <section className='container'>
        <div className='row'>
          <div className={oneWay ? 'col-md-6' : 'col-md-9'}>
            <h3>{TRANSLATIONS[currentLang].SEARCHTRIPS}</h3>
            <Tabs onSelect={(index) => { this.setState({ oneWay: index === 1 }) }}>
              <TabList>
                <Tab>{TRANSLATIONS[currentLang].BOTHWAYS}</Tab>
                <Tab>{TRANSLATIONS[currentLang].ONEWAY}</Tab>
              </TabList>

              <TabPanel>
                <HomepageBookingForm currentLang={currentLang} />
              </TabPanel>
              <TabPanel>
                <HomepageBookingForm currentLang={currentLang} oneWay />
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </section>
    )
  }
}

export default SectionBookingForm
