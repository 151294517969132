import React from 'react'
import Navigation from './Navigation.js'
import { useStaticQuery, graphql } from 'gatsby'

export const menuFragment = graphql`
  fragment menuWithSubnav on ContentfulNavigationWithSubNavConnection {
    nodes {
      navigationItems {
        title
        pageReference {
          slug
          title
        }
        subItems {
          title
          pageReference {
            slug
            title
          }
        }
      }
    }
  }

  fragment sisterPageFragment on ContentfulPageConnection {
    edges {
      node {
        contentful_id
        slug
        node_locale
      }
    }
  }
`

const menuQuery = graphql`
  query {
    isMenu: allContentfulNavigationWithSubNav(filter: {node_locale: {eq: "is-IS"}}) {
      ...menuWithSubnav
    }

    enMenu: allContentfulNavigationWithSubNav(filter: {node_locale: {eq: "en-US"}}) {
      ...menuWithSubnav
    }

    isPages: allContentfulPage(filter: {node_locale: {eq: "is-IS"}}) {
      ...sisterPageFragment
    }

    enPages: allContentfulPage(filter: {node_locale: {eq: "en-US"}}) {
      ...sisterPageFragment
    }
  }
`

export default props => {
  const data = useStaticQuery(menuQuery)

  return (
    <Navigation
      data={data}
      {...props}
    />
  )
}
