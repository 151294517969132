import React, { useState, useEffect } from 'react'
import fetch from 'isomorphic-fetch'
import { WeatherUpdates } from '..'

const indexKeys = {
  date: 0,
  waveheight: 1,
  windSpeed: 6,
  tempature: 7
}

async function getData () {
  const res = await fetch(`${process.env.GATSBY_BASE_SERVER_URL}/api/v1/waves`)
  const json = await res.json()

  const arr = json.forecast

  return arr
}

const WeatherUpdateData = ({ currentLang }) => {
  const [weatherUpdateData, setWeatherUpdateData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    getData().then(setWeatherUpdateData).then(() => setIsLoading(false))
  }, [])

  return (
    <WeatherUpdates weatherUpdateData={weatherUpdateData} currentLang={currentLang} isLoading={isLoading} />
  )
}

export default WeatherUpdateData
