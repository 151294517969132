import React, { Component } from 'react'
import * as styles from './Header.module.scss'
import { Navigation, Logo, MobileNavigation, Burger } from '../'
import className from 'classnames'
import Globe from '../../images/svg-components/Globe.js'
import { getSisterPageUrl } from '../../utils'
import { Link } from 'gatsby'

class Header extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentLang: 'Íslenska',
      showMenu: false
    }

    this.toggleMobileMenuDisplay = this.toggleMobileMenuDisplay.bind(this)
  }

  componentDidMount () {
    window.addEventListener('resize', () => {
      this.setState({
        showMenu: false
      })
    })
  }

  toggleMobileMenuDisplay () {
    const { showMenu } = this.state
    this.setState({
      showMenu: !showMenu
    })
  }

  render () {
    const { displayEyjarMask, currentLang, currentPageId, data } = this.props
    const { showMenu } = this.state
    const headerClasses = className('container d-flex pt-4', styles.container, {
      'pb-4 mb-0': showMenu,
      [styles.fixed]: showMenu
    })
    const IS = 'is-IS'
    const EN = 'en-US'
    const langData = {
      [IS]: {
        'prefix': '/',
        'otherLang': 'en-US',
        'nicename': 'ÍSL',
        'menu': data.isMenu,
        'otherLangpages': data.enPages,
        'myPagesCaption': 'Mínar síður',
        'myPagesUrl': 'https://book.herjolfur.is/innskraning'
      },
      [EN]: {
        'prefix': '/en/',
        'otherLang': 'is-IS',
        'nicename': 'ENG',
        'menu': data.enMenu,
        'otherLangpages': data.isPages,
        'myPagesCaption': 'Login',
        'myPagesUrl': 'https://book.herjolfur.is/login'
      }
    }
    const currentLangData = currentLang ? langData[currentLang] : langData[EN]

    const otherLangNicename = langData[currentLangData.otherLang].nicename
    const globeStyles = className(styles.globe)
    const mobileLanguageSwitchContainer = className('d-md-none', styles.langButton)
    const sisterPageUrl = getSisterPageUrl(
      currentPageId,
      currentLangData.otherLangpages,
      currentLang
    )

    return (
      <header className={headerClasses}>
        <Logo currentLang={currentLang} />
        <div className={mobileLanguageSwitchContainer}>
          <div className={globeStyles}>
            <Globe />
          </div>
          <Link to={sisterPageUrl}>{otherLangNicename}</Link>
        </div>
        <Burger clickEvent={this.toggleMobileMenuDisplay} active={showMenu} />
        <Navigation displayEyjarMask={displayEyjarMask} currentLang={currentLang} currentPageId={currentPageId} />
        <MobileNavigation displayMobileMenu={showMenu} currentLang={currentLang} currentPageId={currentPageId} />
      </header>
    )
  }
}

export default Header
