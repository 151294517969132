import React from 'react'
import className from 'classnames'

const Button = ({ children, icon, ...props }) => {
  const btnClasses = className('btn btn--primary')
  return (
    <button className={btnClasses} {...props}>
      {icon &&
        <span className='btn--icon'>
          {icon}
        </span>
      }
      {children}
    </button>
  )
}

export default Button
