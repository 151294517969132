import React from 'react'
import logo from '../../images/logo.svg'
import { Link } from '../'

const Logo = ({ currentLang }) => {
  const link = currentLang === 'en-US' ? '/en' : '/'

  return (
    <div className='mr-auto'>
      <Link
        to={link}>
        <img src={logo} alt='Herjólfur logo' />
      </Link>
    </div>
  )
}

export default Logo
