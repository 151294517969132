import React from 'react'

/* eslint max-len: ["error", { "code": 5000 }] */
const SvgComponent = ({ fill = '#2D2D2D' }) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
    <g id='car/dark' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='travel-car-copy-5' transform='translate(0.000000, 2.000000)' fill={fill} fillRule='nonzero'>
        <path d='M2,12 L2,9 L1,9 C0.44771525,9 6.76353751e-17,8.55228475 0,8 C-6.76353751e-17,7.44771525 0.44771525,7 1,7 L2,7 L6,0 L14,0 L18,7 L19,7 C19.5522847,7 20,7.44771525 20,8 C20,8.55228475 19.5522847,9 19,9 L18,9 L18,15 C18,15.5522847 17.5522847,16 17,16 L16,16 C15.4477153,16 15,15.5522847 15,15 L15,14 L5,14 L5,15 C5,15.5522847 4.55228475,16 4,16 L3,16 C2.44771525,16 2,15.5522847 2,15 L2,12 Z M15.86,7 L13,2 L7,2 L4.14,7 L15.86,7 Z M5.5,12 C6.0358984,12 6.53108894,11.7141016 6.79903814,11.25 C7.06698735,10.7858984 7.06698735,10.2141016 6.79903814,9.74999999 C6.53108894,9.28589837 6.0358984,8.99999998 5.5,9 C4.67157288,9 4,9.67157288 4,10.5 C4,11.3284271 4.67157288,12 5.5,12 Z M14.5,12 C15.0358984,12 15.5310889,11.7141016 15.7990381,11.25 C16.0669873,10.7858984 16.0669873,10.2141016 15.7990381,9.74999999 C15.5310889,9.28589837 15.0358984,8.99999998 14.5,9 C13.6715729,9 13,9.67157288 13,10.5 C13,11.3284271 13.6715729,12 14.5,12 Z' id='Shape' />
      </g>
    </g>
  </svg>
)

export default SvgComponent
