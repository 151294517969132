import React from 'react'

/* eslint max-len: ["error", { "code": 1000 }] */
const SvgComponent = props => (
  <svg width='14px' height='15px' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 15'>
    <g id='Hönnun' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Forsíða-V2' transform='translate(-442.000000, -3230.000000)' fill='#FFFFFF'>
        <g id='Footer' transform='translate(0.000000, 3089.000000)'>
          <g id='location-current' transform='translate(442.000000, 141.697860)'>
            <polygon id='Path' points='0 0 14 5.6 8.4 8.4 7 14' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent
