import React, { useState } from 'react'
import * as styles from './MobileNavigation.module.scss'
import { Link } from 'gatsby'
import classNames from 'classnames'

const IS = 'is-IS'
const EN = 'en-US'

export default ({ displayMobileMenu, currentLang, currentPageId, data }) => {
  const langData = {
    [IS]: {
      'prefix': '/',
      'otherLang': 'en-US',
      'nicename': 'Íslenska',
      'menu': data.isMenu,
      'otherLangpages': data.enPages,
      'myPagesCaption': 'Mínar síður',
      'myPagesUrl': 'https://book.herjolfur.is/innskraning'
    },
    [EN]: {
      'prefix': '/en/',
      'otherLang': 'is-IS',
      'nicename': 'English',
      'menu': data.enMenu,
      'otherLangpages': data.isPages,
      'myPagesCaption': 'Login',
      'myPagesUrl': 'https://book.herjolfur.is/login'
    }
  }

  const currentLangData = currentLang ? langData[currentLang] : langData[EN]
  const pageUrl = slug => {
    return `${currentLangData.prefix}${slug}`
  }

  const [ { navigationItems: rawNavItems } ] = currentLangData.menu.nodes
  const navigationItems = rawNavItems.map(item => ({
    url: pageUrl(item.pageReference.slug),
    title: item.title,
    childItems: item.subItems.map(({ title, pageReference: { slug } }) => ({
      url: pageUrl(slug),
      title
    }))
  }))

  const containerClasses = classNames('d-none', 'd-lg-none', styles.container, {
    'd-block': displayMobileMenu
  })
  const navClasses = classNames(styles.nav, 'align-self-center')

  return (
    <div className={containerClasses}>
      <nav className={navClasses}>
        {navigationItems && navigationItems.map(item => (
          <NavigationItem
            key={item.url}
            url={item.url}
            title={item.title}
            childItems={item.childItems}
          />
        ))}
        <NavigationItem>
          <a
            href={currentLangData.myPagesUrl}
            target='_blank'
            rel='no-follow'
          >{currentLangData.myPagesCaption}</a>
        </NavigationItem>
      </nav>
    </div>
  )
}

/**
 * Represents each item in the menu
 *
 * @param {props} props
 */
const NavigationItem = props => {
  const {
    children,
    childItems = []
  } = props

  return (
    <div>
      {children}
      {childItems.length > 0 && (
        childItems.map(({ url, title }, index) => (
          <Link key={index} to={url}>{title}</Link>
        ))
      )}
    </div>
  )
}
