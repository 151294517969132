import React from 'react'
import * as styles from './EyjarMask.module.scss'
import VideoMask from '../VideoMask'
import classNames from 'classnames'
import { get } from 'lodash'

const EyjarMask = props => {
  const containerClasses = classNames(styles.container)
  const videoUrl = get(props, 'data.allContentfulHomepage.edges[0].node.heroVideo.file.url', null)

  return (
    <div className='container'>
      {videoUrl &&
        <div className={containerClasses} >
          <div className={styles.inner} >
            <VideoMask className={styles.svg} video={videoUrl} />
          </div>
        </div>
      }
    </div>
  )
}

export default EyjarMask
