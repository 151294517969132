import React from 'react'

/* eslint max-len: ['error', { 'code': 5000 }] */
const SvgComponent = props => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.00012207 13.0197H19.9998V10.2988H0.00012207V13.0197Z" fill="#2D2D2D"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.00012207 6.89236H5.94567L6.64547 4L0.00012207 4.17151V6.89236Z" fill="#2D2D2D"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.00012207 9.9562H9.02645L8.46648 7.23535H0.00012207V9.9562Z" fill="#2D2D2D"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.487319 15.9217C1.01225 16.0725 1.51789 16.0039 1.9721 15.7257C2.61982 15.3291 3.2849 15.3169 3.98277 15.5469C4.31354 15.6555 4.64721 15.7571 4.97959 15.8622C5.62474 16.0658 6.24642 16.0397 6.83307 15.6785C7.36829 15.3487 7.9379 15.3172 8.53001 15.4931C8.88135 15.5976 9.23495 15.698 9.58147 15.8172C10.2941 16.0627 10.9743 16.0696 11.642 15.6602C12.1335 15.3587 12.6786 15.3147 13.2364 15.4821C13.6404 15.6029 14.0451 15.7228 14.4476 15.8493C15.1467 16.0684 15.8086 16.0592 16.4505 15.6426C16.8826 15.3625 17.3795 15.3229 17.8845 15.4566C18.1231 15.5202 18.3619 15.5831 18.6004 15.6464L20 13.3623H0V15.7801C0.162332 15.8273 0.324665 15.8751 0.487319 15.9217Z" fill="#2D2D2D"/>
  </svg>
  
)

export default SvgComponent
