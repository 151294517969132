import React from 'react'

const GaEvent = ({ isOpen, question, children, onClick }) => {
  if (typeof window !== 'undefined' && window.gtag && isOpen) {
    window.gtag('event', 'click', {
      event_category: 'FAQ',
      event_label: question,
      transport_type: 'beacon'
    })
  }

  return <div onClick={onClick}>{children}</div>
}

export default GaEvent
