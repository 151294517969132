import React, { Component } from 'react'
import Cmp from './AllRecommended'
import { StaticQuery, graphql } from 'gatsby'

class Connected extends Component {
  render () {
    return (
      <StaticQuery
        query={graphql`
          query {
            allContentfulRecommended(filter: {node_locale: {eq: "en-US"}}, sort: {fields: title, order: ASC}) {
              edges {
                node {
                  title
                  url        
                  image {
                    gatsbyImageData(layout: FIXED, width: 200)
                  }
                }
              }
            }                  
          }
        `}
        render={data => <Cmp data={data} {...this.props} />}
      />
    )
  }
}

export default Connected
