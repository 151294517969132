import React from 'react'
import * as styles from './Timeline.module.scss'
import TimelineCard from '../TimelineCard'
import classNames from 'classnames'

const Timeline = ({ content }) => {
  const { timelineCard } = content
  const left = timelineCard.filter((i, k) => k % 2 === 0)
  const right = timelineCard.filter((i, k) => k % 2 !== 0)
  return (
    <section className='container'>
      {/* Mobile timeline */}
      <div className='row d-inline d-lg-none'>
        <div className='col d-flex flex-column align-items-center'>
          {timelineCard.map(({
            cardImage,
            date,
            title,
            text,
            video,
            imageGallery
          }, key) =>
            <div className={styles.card} key={key}>
              <TimelineCard
                cardImage={cardImage}
                date={date}
                text={text}
                title={title}
                video={video}
                imageGallery={imageGallery}
              />
            </div>
          )}
        </div>
      </div>
      {/* Desktop timeline */}
      <div className={classNames('row d-none d-lg-flex justify-content-between pt-md-3', styles.container)}>
        <div className={classNames(styles.col, styles.leftCol)}>
          {left.map(({
            cardImage,
            date,
            title,
            text,
            video,
            imageGallery
          }, key) =>
            <div className={styles.card} key={key}>
              <TimelineCard
                cardImage={cardImage}
                date={date}
                text={text}
                title={title}
                video={video}
                imageGallery={imageGallery}
              />
            </div>
          )}
        </div>
        <div className={classNames(styles.col, styles.rightCol)}>
          {right.map(({
            cardImage,
            date,
            title,
            text,
            video,
            imageGallery
          }, key) =>
            <div className={styles.card} key={key}>
              <TimelineCard
                cardImage={cardImage}
                date={date}
                text={text}
                title={title}
                video={video}
                imageGallery={imageGallery}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default Timeline
