import React from 'react'
import { Slider, Card, Link } from '../'
import { TRANSLATIONS } from '../../misc/translations'

const SectionSlider = ({ sliders, title, currentLang, allRecommendedPageUrl, hasAllRecommendedPage }) => {
  return (
    <section>
      <div className='container section-spacing'>
        <h1 className='hdln--1'>{title}</h1>
        {hasAllRecommendedPage &&
          <Link to={allRecommendedPageUrl} className='link--more d-none d-lg-inline' useChevron>{TRANSLATIONS[currentLang].SEEALLRECOMMENDED}</Link>
        }
      </div>
      {sliders &&
        <Slider>
          {sliders.map((slide, i) => {
            return (
              <Card
                key={i}
                text={slide.title}
                image={slide.image}
                link={slide.url}
                className='swiper-slide'
                trackClick
                i={i} />
            )
          })}
        </Slider>
      }
      {hasAllRecommendedPage &&
        <div className='container'>
          <div className='row'>
            <Link to={allRecommendedPageUrl} className='col mt-3 t-align--center link--more d-inline d-lg-none' useChevron>{TRANSLATIONS[currentLang].SEEALLRECOMMENDED}</Link>
          </div>
        </div>
      }
    </section>
  )
}

export default SectionSlider
