import React from 'react'

/* eslint max-len: ["error", { "code": 5000 }] */
const SvgComponent = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width='20px' height='20px' viewBox='0 0 20 20'>
    <g id='calendar/dark' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='calendar-copy' transform='translate(1.000000, 0.000000)' fill='#2D2D2D' fillRule='nonzero'>
        <path d='M0,4 C0,2.9 0.9,2 2,2 L16,2 C17.1045695,2 18,2.8954305 18,4 L18,18 C18,19.1045695 17.1045695,20 16,20 L2,20 C0.8954305,20 2.22044605e-16,19.1045695 0,18 L0,4 Z M2,6 L2,18 L16,18 L16,6 L2,6 Z M4,0 L6,0 L6,2 L4,2 L4,0 Z M12,0 L14,0 L14,2 L12,2 L12,0 Z M4,9 L6,9 L6,11 L4,11 L4,9 Z M4,13 L6,13 L6,15 L4,15 L4,13 Z M8,9 L10,9 L10,11 L8,11 L8,9 Z M8,13 L10,13 L10,15 L8,15 L8,13 Z M12,9 L14,9 L14,11 L12,11 L12,9 Z M12,13 L14,13 L14,15 L12,15 L12,13 Z' id='Shape' />
      </g>
    </g>
  </svg>
)

export default SvgComponent
