import React, { Fragment, useMemo, useState } from "react";
import cn from "classnames";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import ArrowRight from "../../images/svg-components/ArrowRight.js";

import * as styles from "./TripSelect.module.scss";

const tripList = [
  {
    fromPort: "LAN",
    toPort: "VEY",
    from: "Landeyjahöfn",
    to: "Vestmannaeyjar",
  },
  {
    fromPort: "VEY",
    toPort: "LAN",
    from: "Vestmannaeyjar",
    to: "Landeyjahöfn",
  },
];

export const TripSelect = React.forwardRef(
  ({ children, port, returnTrip, onChange, ...props }, forwardedRef) => {
    const [value, setValue] = useState([]);

    const trips = [...tripList];

    const selectedPort = trips.find((x) => x.fromPort === port);

    useMemo(() => {
      if (port) {
        setValue([
          <span className={styles.value}>
            <span>{selectedPort.from}</span>
            <ArrowRight />
            <span>{selectedPort.to}</span>
          </span>,
        ]);
      }
    }, [port]);

    return (
      <div className={styles.wrapper}>
        <DropdownMenu.Root {...props}>
          <DropdownMenu.Trigger asChild>
            <button
              ref={forwardedRef}
              className={cn("form-control", styles.trigger)}
            >
              <div className={cn(styles.label, styles.labelValue)}>
                {value ?? "value"}
              </div>
            </button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content
            className={styles.content}
            side="bottom"
            align="start"
            sideOffset={18}
          >
            <DropdownMenu.Arrow asChild>
              <div className={styles.arrow}>
                <svg
                  width="30"
                  height="15"
                  viewBox="0 0 30 10"
                  preserveAspectRatio="none"
                >
                  <polygon points="0,0 30,0 15,10"></polygon>
                </svg>
              </div>
            </DropdownMenu.Arrow>
            {trips.map(({ fromPort, toPort, from, to }, index) => {
              const label = (
                <Fragment>
                  <span>{from}</span>
                  <ArrowRight />
                  <span>{to}</span>
                </Fragment>
              );

              return (
                <DropdownMenu.Item
                  key={index}
                  value={`trip-${index}`}
                  className={styles.item}
                  onSelect={(e) => {
                    onChange &&
                      onChange({
                        fromPort: returnTrip ? toPort : fromPort,
                        toPort: returnTrip ? fromPort : toPort,
                      });
                  }}
                >
                  <div className={styles.label}>{label}</div>
                </DropdownMenu.Item>
              );
            })}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>
    );
  }
);

export default TripSelect;
