import React, { Component, Fragment } from 'react'
import { Slider, Link } from '../'
import { TRANSLATIONS } from '../../misc/translations'
import { get, remove } from 'lodash'
import Arrow from '../../images/svg-components/Arrow.js'
import ExclamationMark from '../../images/svg-components/ExclamationMark.js'
import classNames from 'classnames'

class Notifications extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }
  render () {
    const { currentLang, data } = this.props
    const notificationsArr = currentLang === 'en-US' ? data.enNotifications.edges : data.isNotifications.edges
    const notificationsLink = currentLang === 'en-US' ? '/en/announcements' : '/tilkynningar'

    return (
      <Fragment>
        {notificationsArr.length > 0 &&
        <div className='container notification-container mt-4' >
          <div className='row'>
            <div className='col-12 col-md-7'>
              <div className='notification mb-2 d-md-flex'>
                <Slider
                  totalSlides={notificationsArr.length}
                  noExtraSlide
                  hasIndicators
                  spaceBetween={44}
                  navigation={{
                    nextEl: '.swiper-button--next',
                    prevEl: '.swiper-button--prev'
                  }}
                  slidesPerView={1.2}
                  slidesPerGroup={1}
                  freeMode={false}
                  centeredSlides
                  breakpoints={{
                    768: {
                      slidesPerView: 1.2
                    },
                    900: {
                      slidesPerView: 1.2
                    },
                    1300: {
                      slidesPerView: 1.2
                    }
                  }}
                >
                  {notificationsArr.map((item, i) => {
                    const title = get(item, 'node.title', '')
                    const content = get(item, 'node.content.childMarkdownRemark.html', '')
                    const isOrange = get(item, 'node.orangeDisplay', false)
                    
                    // remove default values notification 7zYxMa5OYDEV7HAdEgSX4y
                    if (title && item.node.contentful_id !== '7zYxMa5OYDEV7HAdEgSX4y') {
                      return (
                        <div
                          className={classNames('swiper-slide', {
                            'swiper-slide--orange': isOrange
                          })}
                          key={i}
                        >
                          <Link to={'/tilkynningar/#' + item.node.contentful_id}>
                            {isOrange !== false &&
                              <div className='notification-exclamation'>
                                <ExclamationMark />
                              </div>
                            }
                            <div className='swiper-inner-container'>
                              <div className='swiper-inner'>
                                <h2 className='hdln--7'>{title}</h2>
                                {content !== '' &&
                                  <div className='notification-content' dangerouslySetInnerHTML={{ __html: content }} />
                                }
                              </div>
                            </div>
                          </Link>
                        </div>
                      )
                    }
                  })}
                </Slider>
              </div>
            </div>
          </div>
          <Link to={notificationsLink}><span className='hdln--3 lh--26'>{TRANSLATIONS[currentLang].SEEALLNOTIFICATIONS} <Arrow /></span></Link>
        </div>
        }
      </Fragment>
    )
  }
}

export default Notifications
