import React from 'react'

/* eslint max-len: ["error", { "code": 5000 }] */
const SvgComponent = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width='12px' height='8px' viewBox='0 0 12 8'>
    <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Top-menu/align-bottom' transform='translate(-927.000000, -65.000000)' fill='#2D2D2D'>
        <g id='Group-2' transform='translate(836.000000, 62.000000)'>
          <g id='cheveron-down' transform='translate(91.000000, 3.000000)'>
            <polygon id='Path' points='5.293 6.95 6 7.657 11.657 2 10.243 0.586 6 4.828 1.757 0.586 0.343 2' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SvgComponent
