import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as styles from './Burger.module.scss'
import classNames from 'classnames'

class Burger extends Component {
  render () {
    const { active, clickEvent } = this.props
    const containerClass = classNames('d-block', 'd-md-none', styles.container, { [styles.active]: active })

    return (
      <div className={styles.buttonWrapper}>
        <button
          className={containerClass}
          onClick={clickEvent}
        >
          <div
            className={styles.line}
          />
          <div
            className={styles.line}
          />
          <div
            className={styles.line}
          />
        </button>
      </div>
    )
  }
}

Burger.propTypes = {
  /** Toggles component active state */
  active: PropTypes.bool,
  /** onClick event handler */
  clickEvent: PropTypes.func
}
Burger.defaultProps = {
  active: false
}

export default Burger
